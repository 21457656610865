import axios from "axios";
import { IProject } from "../models/applicationState";

export const dumpModel = async (
  project: IProject,
  doctype: any,
  setDoctype: Function
) => {
  const modelObj = {};
  const datetime = new Date();
  modelObj[`${project.trainRecord.modelInfo.modelId}`] = {
    accuracy: `${project.trainRecord.averageAccuracy}`,
    createdAt: datetime,
  };
  const Obj =
    "allModels" in doctype ? { ...doctype.allModels, ...modelObj } : modelObj;
    var checkRootFolder=  project.folderPath.includes('preprocessed_data')
            const doctyp = checkRootFolder ? project.folderPath
                .replace("preprocessed_data", "")
                .replace("/", "") : project.folderPath
                .replace("pdfs", "")
                .replace("/", "") ; 
  
  const res = await axios.patch(
    `${process.env.REACT_APP_SERVER_URL}/doctypes/${doctyp}`,
    { allModels: Obj },
    {
      // Config
    }
  );
  console.log(
    "Response of Dump Model: status code :",
    res.status,
    " Status Text :",
    res.statusText
  );
  if (res.status == 200) {
    const resDoctype = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/doctypes/${doctyp}`
    );
    setDoctype(resDoctype.data);
  }
};

export const setModelToDb = async (project: IProject, modelid: String) => {
  var checkRootFolder=  project.folderPath.includes('preprocessed_data')
  const doctyp = checkRootFolder ? project.folderPath
      .replace("preprocessed_data", "")
      .replace("/", "") : project.folderPath
      .replace("pdfs", "")
      .replace("/", "") ; 
  const res = await axios.patch(
    `${process.env.REACT_APP_SERVER_URL}/doctypes/${doctyp}`,
    { model: modelid },
    {
      // Config
    }
  );

  console.log(
    "Response of setModelToDb: status code :",
    res.status,
    " Status Text :",
    res.statusText
  );
  //Redirect to Haloid Page
  window.open(
    `${process.env.REACT_APP_CLIENT_URL}/doctypes/${doctyp}`,
    "_self"
  );
};
