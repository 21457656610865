import React from "react";
import { MyModal } from "./common/myModal";
import { PrimaryButton } from "@fluentui/react";
import { getPrimaryGreenTheme } from "../../common/themes";

const styleScroll: any = {
  margin: "4px, 4px",
  padding: "4px",
  width: "500px",
  height: "700px",
  overflowX: "hidden",
  overflowY: "auto",
  textAlign: "justify",
};

export const TrainPagePublishModal = ({
  isOpenModal,
  toggle,
  doctype,
  handleInputChange,
  checkBox,
  handlePublish,
}) => (
  <MyModal isOpen={isOpenModal} toggle={toggle}>
    <div style={styleScroll}>
      <h3>Select Model to Publish:</h3>
      {Object.entries("allModels" in doctype ? doctype.allModels : []).map(
        ([key, value], idx) => (
          <span key={key || idx}>
            <input
              type="radio"
              value={idx}
              name={String(idx)}
              onChange={(e) => handleInputChange(idx)}
              checked={idx === checkBox}
            />
            <span
              style={
                key === doctype.model
                  ? { color: "red" }
                  : key ===
                    Object.keys(doctype.allModels)[
                      Object.keys(doctype.allModels).length - 1
                    ] /*this.state.project.trainRecord.modelInfo.modelId*/
                  ? { color: "#6CB4EE" }
                  : { color: "white" }
              }
            >
              {"   "}Model-ID : {key || "None"}
              <br />
              Accuracy :{" "}
              {value
                ? value["accuracy"]
                  ? value["accuracy"] === "NA"
                    ? "NA"
                    : Number(value["accuracy"]) * 100 + "%"
                  : "None"
                : ""}
              <br />
              Created At{" "}
              {value
                ? "Date: " +
                  String(value["createdAt"].split("T")[0]) +
                  " Time: " +
                  String(value["createdAt"].split("T")[1])
                : ""}
              <br />
            </span>
            <br />
          </span>
        )
      )}
      <PrimaryButton
        style={{
          backgroundColor: "grey",
          borderBlockColor: "grey",
        }}
        onClick={toggle}
      >
        Dismiss
      </PrimaryButton>
      {"   "}
      <PrimaryButton theme={getPrimaryGreenTheme()} onClick={handlePublish}>
        Confirm
      </PrimaryButton>
    </div>
  </MyModal>
);
