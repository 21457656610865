import Axios from "axios";
var createHost = require('cross-domain-storage/host');
const createGuest = require('cross-domain-storage/guest');


var accessStorage =  createGuest(`${process.env.REACT_APP_CLIENT_URL}`);
console.log("hiiout");
Axios.interceptors.request.use(async request => {     
    //   var accessStorage = await createGuest(`${process.env.REACT_APP_CLIENT_URL}`);

                  var token= await new Promise(async(resolve)=>{
                      await accessStorage.get('token', async function(error, value) {
                      if(error){
                          console.log(error);   
                      }else{
              
                  await window.localStorage.setItem('token',value);
                  return resolve(value)
                      }  })     
                    
        }).then(async(value)=>{
            var accessToken = await window.localStorage.getItem('token');
          request.headers["Authorization"] = `${accessToken}`; 
        

        })
       
        return Promise.resolve(request);
   
  
    }
   );
Axios.interceptors.response.use(response => response , async error=>{
    if(error.response.status===401){  
     
        var accessStorage = await createGuest(`${process.env.REACT_APP_CLIENT_URL}`);

                  var token= await new Promise(async(resolve)=>{
                      await accessStorage.get('token', async function(error, value) {
                      if(error){
                          console.log(error);   
                      }else{
              
                  await window.localStorage.setItem('token',value);
                  return resolve(value)
                      }  })     
                    
        }).then(async(value)=>{
            var accessToken = await window.localStorage.getItem('token');
            Axios.defaults.headers["Authorization"] = `${accessToken}`; 
        

        })
       
        return Promise.resolve(error);
     
}    
})
