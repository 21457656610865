import React from "react";
import "./haloid.scss";

export class Haloid extends React.Component {
  constructor(props) {
    super(props);
  }

  private handleClick = () => {
    window.open(`${process.env.REACT_APP_CLIENT_URL}`, "_self");
  };

  public render() {
    return (
      <div onClick={this.handleClick} className="haloid-logo">
        HalOID
      </div>
    );
  }
}
