import shortid from "shortid";
import { IProject } from "../models/applicationState";

//function to create project if not found
export const createProject = (
  projectId: String,
  onFormSubmit: Function,
  filePath : String,
): any => {
  var projectToCreate: IProject = {
    id: shortid.generate(),
    name: `Project-${projectId}`,
    version: undefined,
    securityToken: `Project-${projectId} Token`,
    description: "",
    tags: [],
    sourceConnection: {
      id: process.env.REACT_APP_CONNECTION_ID,
      name: process.env.REACT_APP_CONNECTION_NAME,
      providerOptions: {
        sas: process.env.REACT_APP_SAS_URL,
      },
      providerType: "azureBlobStorage",
    },
    apiUriBase: process.env.REACT_APP_API_URI_BASE,
    apiKey: process.env.REACT_APP_API_KEY,
    apiVersion: undefined,
    folderPath: `${projectId}/${filePath}`,
    trainRecord: {
      modelInfo: {
        modelId: null,
        createdDateTime: null,
        modelName: null,
      },
    },
    recentModelRecords: [],
    predictModelId: null,
  };
  onFormSubmit(projectToCreate);
  return null;
};
