import {
  AUTH_GET_ROLES,
  AUTH_GET_ROLES_SUCCESS,
  AUTH_GET_ROLES_FAIL,
} from "../actions/types";

export const AuthenticationState = {
  Unauthenticated: "Unauthenticated",
  InProgress: "InProgress",
  Authenticated: "Authenticated",
};

export const AuthenticationActions = {
  Initializing: "AAD_INITIALIZING",
  Initialized: "AAD_INITIALIZED",
  LoginSuccess: "AAD_LOGIN_SUCCESS",
  LoginFailed: "AAD_LOGIN_FAILED",
  LoginError: "AAD_LOGIN_ERROR",
  ClearError: "AAD_CLEAR_ERROR",
  LogoutSuccess: "AAD_LOGOUT_SUCCESS",
  AcquiredIdTokenSuccess: "AAD_ACQUIRED_ID_TOKEN_SUCCESS",
  AcquiredIdTokenError: "AAD_ACQUIRED_ID_TOKEN_ERROR",
  AcquiredAccessTokenSuccess: "AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS",
  AcquiredAccessTokenError: "AAD_ACQUIRED_ACCESS_TOKEN_ERROR",
  AuthenticatedStateChanged: "AAD_AUTHENTICATED_STATE_CHANGED",
};

const initialState = {
  initializing: false,
  initialized: false,
  idToken: null,
  account: null,
  accessToken: null,
  state: AuthenticationState.Unauthenticated,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthenticationActions.Initializing:
      return {
        ...state,
        initializing: true,
        initialized: false,
      };

    case AuthenticationActions.Initialized:
      return {
        ...state,
        initializing: false,
        initialized: true,
      };

    case AuthenticationActions.AcquiredIdTokenSuccess:
      return {
        ...state,
        idToken: action.payload,
      };

    case AuthenticationActions.AcquiredAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.payload,
      };

    case AuthenticationActions.AcquiredAccessTokenError:
      return {
        ...state,
        accessToken: null,
      };

    case AuthenticationActions.LoginSuccess:
      return {
        ...state,
        account: action.payload.account,
      };

    case AuthenticationActions.LoginError:
      return state;

    case AuthenticationActions.AcquiredIdTokenError:
      return state;

    case AuthenticationActions.LogoutSuccess:
      return { ...state, idToken: null, accessToken: null, account: null };

    case AuthenticationActions.AuthenticatedStateChanged:
      return {
        ...state,
        state: action.payload,
      };

    case AUTH_GET_ROLES:
      return { ...state, progress: { roles: true } };

    case AUTH_GET_ROLES_SUCCESS:
      return {
        ...state,
        account: { ...state.account, ...action.account, admin: true },
        progress: { roles: false },
      };

    case AUTH_GET_ROLES_FAIL:
      return { ...state, progress: { roles: false } };

    default:
      return state;
  }
};
