import React from "react";
import { Modal, ModalBody } from "reactstrap";

export const MyModal = ({ children, isOpen, toggle }) => (
  <div>
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>{children}</ModalBody>
    </Modal>
  </div>
);
